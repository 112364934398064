import React from 'react';
import { Route, IndexRoute } from 'react-router';

/**
 * Import all page components here
 */
import App from './App';
import HomePage from './components/HomePage';
import ProjectsPage from './components/ProjectsPage';
import AboutMePage from './components/AboutMePage';

/**
 * All routes go here.
 * Don't forget to import the components above after adding new route.
 */
export default (
  <Route path="/" component={App}>
    <IndexRoute component={HomePage} />
    <Route path="/projects" component={ProjectsPage} />
    <Route path="/about" component={AboutMePage} />
  </Route>
);