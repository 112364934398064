import React, { Component } from 'react';
import Modal from 'react-modal';
import "animate.css/animate.min.css";
import Aos from 'aos';
import "aos/dist/aos.css";
import MyCV from '../utils/Theo-Hayeck-CV.pdf';
import { Link as LinkRouter } from 'react-router';
Modal.setAppElement('#root');
export class ProjectsPage extends Component {

   state = {};

    AosDidMount(){
        Aos.init({duration: 1500});
        Aos.refresh();
    }

    render() {
        this.AosDidMount();
        return (
            <React.Fragment>
                <div className="App">
                <body className="App-body">
                
                <div className="App-navbar">
                    <LinkRouter to="/" className="navbar-button">Home</LinkRouter>
                    <LinkRouter to="/about" className="navbar-button">About Me</LinkRouter>
                    <LinkRouter to="/projects" className="navbar-button">Projects</LinkRouter>
                    <a className="navbar-button" href={MyCV} download="Theo-Hayeck-CV.pdf">Download CV</a>
                </div>

                <div className='card-section'>
                <div data-aos="zoom-in" className='text-box-about-me'>
                    <h5 className="card-title">PROJECTS</h5><br></br>

                    <div className='about-me-summary'>
                            <h1>What</h1>
                            <h1>I</h1>
                            <h1>have</h1>
                            <h1>created.</h1>
                    </div>
                </div>
                

                <div data-aos="zoom-in-up" className="text-box">
                    <h3 className='card-title'>Software Engineering</h3><br></br>
                    <h5>Motivational Social Networking Application</h5>
                    <h6>
                        <p>
                        This large scale mobile application for Android and IOS involved the creation of a
                        large code base and experience in debugging,testing and UX. Artificial Intelligence
                        is also being used to develop targeted advertisments to users. Networking and
                        pitching to potential investors has also given me a strong ability to communicate
                        effectively.
                        </p>
                    </h6>
                    <br></br>

                    <h5>Gig Economy Web Application</h5>
                    <h6>
                        <p>
                        Based off services such as Uber, Deliveroo and TaskRabbit, this web
                        application allowed users to request services from third party providers.
                        Implementing payment services such as Stripe.js and API's such as
                        Google Maps API.
                        </p>
                    </h6>
                    <br></br>

                    <h5>Tinder-like Match Making Application</h5>
                    <h6>
                        <p>
                        At university, I was part of a team of 6 people that developed a web application that allowed
                        users to find potential, like-minded, flatmates. This application incorporated the use of various 
                        technologies such as: ASP.Net, Docker, MongoDB and Git. For this project, I also developed an ELO
                        based algorithm that was able to rank users based on desired characteristcs such as cleanliness 
                        and activeness. Users were also ranked depending on how many times they were "swiped right on" by
                        others.
                        </p>
                    </h6>
                    <br></br>

                    <h5>Stendhal - Massive Online Multiplayer Game</h5>
                    <h6>
                        <p>
                        I have contributed to the massive online multiplayer game, Stendhal. This open source game comprises
                        of over 4000 java classes. I implemented increases in functionality for the game, such as the addition
                        of pets that were able to steal objects aquired by players and bring them back to the pets owner. My
                        contribution to Stendhal was also in the form of increasing the game's test coverage, by writing numerous
                        JUnit tests that assess correct functionality for various additional and historic features in the game.
                        </p>
                    </h6>
                    <br></br>

                    <h5>Eventlite: Online Event Finding Platform</h5>
                    <h6>
                        <p>
                        As part of a team of 5, I developed an online event finding web application that enabled users to search for
                        nearby events and venues. Technologies used to develop this app included: Spring, Java, HTML5, and CSS3. The 
                        project also required the use of various api integrations, such as Twitter4J and Google Maps.
                        </p>
                    </h6>
                    <br></br>
                </div>
                <div data-aos="zoom-in-up" className="text-box">
                    <h3 className="card-title">Data Science & Machine Learning</h3><br></br>
                    <h5>Automated Stock/Currency Trading Python Package</h5>
                    <h6>
                        <p>
                        I have developed a package of python programs that can perform both technical
                        and fundamental analysis of stocks/currencies to provide both information on an
                        asset, and to automatically open and close trading positions based upon
                        obtained information. Technical indicators include: RSI, MECD, ATR, Renko,
                        Bolinger Bands and more. Fundamental analysis includes: Piotroski F-Score and
                        the "Magic Formula". The package can perform backtesting strategies also measure the 
                        performance of a portfolio, using metrics like: CAGR, Annualised Volatility, Sharpe and Calmar ratio.
                        Natural Language Processing (NLP) is also used to detect whether online news coverage will have a 
                        positive or negative impact on global oil prices.
                        </p>
                    </h6>
                    <br></br>

                    <h5>Facial Recognition Python Program</h5>
                    <h6>
                        <p>
                        I have developed a python program that is able to identify the subject of a photograph given a set 
                        of images. The program can also attempt to re-create a subject's face given half a photo of the 
                        desired subject. The program can perform experiments on the data set, such as calculating the different
                        testing and training errors depending on the type of classifier used, and is able to select an optimal
                        hyperparameter (lambda) that results in the lowest mean testing/training error. The program implements 
                        the use of Regularised Least Squares, Linear Regression and Binary/Multi-class Classification.
                        </p>
                    </h6>
                    <br></br>

                    <h5>News Article Document Classifier</h5>
                    <h6>
                        <p>
                        This simple Multi-Class Classifier is able to identify what type of news article a given document belongs
                        to based upon the occurences of keywords in the document. A K-NN algorithm was used to create this classifier, 
                        using the pairwise-distances of features inside a document. Again, the program can perform experiments to calculate
                        the testing/training errors and classification accuracy of the classifier given various hyperparameter configuations - 
                        such as varying the K number.
                        </p>
                    </h6>
                    <br></br>

                    <h5>Chicago and San-Francisco Crime Rate Analysis</h5>
                    <h6>
                        <p>
                        As part of my IBM Data Science Professional Certificate, I developed a series of python programs that analyses 
                        the crime rates of both Chicago and San-Francisco. The analysis shows the relationship of various factors that 
                        impact the crime rates of the two cities; such as college enrollment. Data Visualisations such as Scatter and Box
                        plots were produced, as well as Chloropleth Maps.
                        </p>
                    </h6>
                    <br></br>

                    <h5>Content Recommendation Engine</h5>
                    <h6>
                        <p>
                        I have developed a series of python programs that can suggest different movies a user may like to watch next given
                        a provided dataset. This engine can use both content-based and collaborative filtering mechanisms in order to suggest such content.
                        </p>
                    </h6>
                    <br></br>

                    <h5>Computer Vision: Horizon Detection C++ Program</h5>
                    <h6>
                        <p>
                        Using OpenCV, I have developed a C++ program that is able to process images of Earth and identify its horizon.
                        This program implements the Gaussian Blur, Canny Edge Detection and Hough Lines Transformation pipeline.
                        </p>
                    </h6>
                    <br></br>

                    <h5>Robot Localisation Java Program</h5>
                    <h6>
                        <p>
                        In my first year at university, I developed a Java program that implemented a Bayesian Updating algorithm able to 
                        produce probabilities that a virtual robot was in a specific location on a map, given previous predictions and various readings from the robot's
                        laser and touch sensors. 
                        </p>
                    </h6>
                    <br></br>
                </div>
                </div>
            
                </body>
                </div>
            </React.Fragment>
        );
    }

}

export default ProjectsPage;
 