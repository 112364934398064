import React, { Component } from 'react';
import Modal from 'react-modal';
import mobiles from '../utils/Mobiles.png';
import MorningMotivate from '../utils/MM-Final-Print-01.png';
import "animate.css/animate.min.css";
import Aos from 'aos';
import MyCV from '../utils/Theo-Hayeck-CV.pdf';
import "aos/dist/aos.css";
import '../components/HomePage.css';
import { Link as LinkRouter } from 'react-router';


Modal.setAppElement('#root');
export class AboutMePage extends Component {

   state = {};
  
    AosDidMount(){
        Aos.init({duration: 1500});
        Aos.refresh();
    }

    render() {
        this.AosDidMount();
        return (
            <React.Fragment>
                <div className="App">
                <body className="App-body">

                <div className="App-navbar">
                    <LinkRouter to="/" className="navbar-button">Home</LinkRouter>
                    <LinkRouter to="/about" className="navbar-button">About Me</LinkRouter>
                    <LinkRouter to="/projects" className="navbar-button">Projects</LinkRouter>
                    <a className="navbar-button" href={MyCV} download="Theo-Hayeck-CV.pdf">Download CV</a>
                </div>

                    <div className="card-section">
                    <div data-aos="zoom-in" className="text-box-about-me">

                    <h5 className="card-title">ABOUT ME</h5><br></br>

                    <div className='about-me-summary'>
                        <h1>Tech Enthusiast.</h1>
                        <h1>Fast Learner.</h1>
                        <h1>Creative Thinker.</h1>
                    </div>
                        
                        <div>
                            <p>
                                I'm a freelance Software Engineer, interested in mobile and web application development, Data Analytics, and Machine Learning. 
                                I am a graduate of The Univerisity of Manchester, with a First Class (Hons) Computer Science degree; obtaining a final grade of
                                80.4% and putting me within the top 10% of graduating students.
                            </p>
                            <p>
                            I enjoy building complete products and having a holistic approach to development. That is why I have gathered a lot of experience in 
                            most areas of web development ranging from Backend, DevOps, Networking and Infrastructure, all the way to Frontend, UX/UI and Product
                            Design. 
                            </p>
                            <p>
                            Outside of Tech, I like keeping fit, debating, and reading about topics such as; psychology, finance, and politics. When I am not doing something 
                            related to those, I enjoy playing music (either on my: saxophone, melodeon, or bagpipes) as well as morris dancing with
                                <a href="http://www.shropshirebedlams.co.uk" className="link"> The Shropshire Bedlams & Martha Rhoden's Tuppenny Dish</a>
                            </p>
                        </div>

                    </div>
                        <div data-aos="zoom-in-up" className="text-box">
                            <h3 className="card-title">MY SKILLS</h3><br></br>
                            <h3>
                                <div>
                                    <div className="row-group">
                                        <div className="skills-icon"><i class="devicon-java-plain"></i><br/>Java</div>
                                        <div className="skills-icon"><i class="devicon-mongodb-plain"></i><br/>MongoDB</div>
                                        <div className="skills-icon"><i class="devicon-nodejs-plain"></i><br/>Node.js</div>
                                        <div className="skills-icon"><i class="devicon-html5-plain"></i><br/>HTML5</div>
                                    </div>
                                    <div className="row-group">
                                        <div className="skills-icon"><i class="devicon-git-plain"></i><br/>Git</div>
                                        <div className="skills-icon"><i class="devicon-mysql-plain"></i><br/>MySQL</div>
                                        <div className="skills-icon"><i class="devicon-python-plain"></i><br/>Python</div>
                                        <div className="skills-icon"><i class="devicon-react-original"></i><br/>React</div>
                                    </div>
                                    <div className="row-group">
                                        <div className="skills-icon"><i class="devicon-linux-plain"></i><br/>Linux</div>
                                        <div className="skills-icon"><i class="devicon-css3-plain"></i><br/>CSS3</div>
                                        <div className="skills-icon"><i class="devicon-angularjs-plain"></i><br/>Angular</div>
                                        <div className="skills-icon"><i class="devicon-c-plain"></i><br/>C</div>
                                    </div>
                                </div>
                            </h3>
                        </div>
                        
                        <div data-aos="zoom-in-up" className="padding-image">
                            <img src={mobiles} className="mobiles-image" /> 
                        </div>
                        <section id="morning-motivate">
                            <div data-aos="zoom-in" className="text-box-MM">
                            <img src={MorningMotivate} className="App-MM-mobileView"></img>
                            <h2 className="card-title-MM">MORNING MOTIVATE</h2><br></br>
                                <div className="text-and-img-row">
                                <h6>
                                    <p>
                                        I am looking forward to releasing a new, social networking, mobile application called Morning Motivate. 
                                        But what is it?
                                    </p><br></br>
                                    <h4>An Alarm Clock</h4>
                                    <p>
                                        Don't trust yourself, when you first wake up your brain is too tired, with parts relating to thinking
                                        long term not even activated. The alarm clock will automatically play a piece of content (such as a video) to shift your brain into 
                                        an energised state.
                                    </p><br></br>
                                    <h4>A Social Network</h4>
                                    <p>
                                        Share your progress with your friends via goal posts, photos and videos; and subscribe to channels that are best suited to your needs and aspirations.
                                    </p>
                                    <p>
                                        Be accountable. Post your short term and long term goals and get notified when your friends have missed or completed theirs.
                                        Search for groups of like-minded people in your neighborhood. Want to get in shape? Search for local gym goers near you.                                   
                                    </p>
                                </h6>
                                <img src={MorningMotivate} className="App-MM"></img>
                                </div>
                            </div>
                        </section>

                        <section id="what-choose-a-developer">
                            <div data-aos="zoom-in-up" className="text-box">
                            <h3 className="card-title">WHY CHOOSE A DEVELOPER</h3>
                            <br></br>
        
                                <p>
                                    In modern times, companies like Wix and WebFlow make it quick and easy for anyone to produce a website.
                                    So why would you choose to use a web-developer?
                                </p><br></br>
                                <h4>Stand out from the crowd</h4>
                                <p>
                                    Using a web-developer to create a custom made website gives you exponentially more functionality, nearly
                                    impossible to replicate using a traditional website-making tool. Your website is the face of your business, using a
                                    web-developer to create a unique looking website will make you stand out from your competition.
                                </p><br></br>
                                <h4>Responsive Web Design</h4>
                                <p>
                                    With a high percentage of users using ipads and Smartphones to access the Internet, it is important that your web design is 
                                    built to be compatible with the latest mobile technologies.
                                </p>
                                <p>
                                    If your website is not designed with mobile technologies in mind then this could cost you big. Mobile search traffic is 
                                    growing every month and if your website is not mobile user friendly, audiences are likely to ‘bounce off’ of your website.
                                </p>
                                <p>
                                    The good news is, there is no need to create a separate version of your website. Your web design just needs to be responsive, 
                                    meaning it is compatible with a wide range of mobile devices. A top web designer will build your website using this technology as 
                                    standard, saving you lots of money long term.
                                </p>
                            </div>
                        </section>
                    </div>
                </body>
                </div>

            </React.Fragment>
        );
    }

}

export default AboutMePage;
 