import React, { Component } from 'react';
import Modal from 'react-modal';
import avatar from '../utils/avatar.jpeg';
import "animate.css/animate.min.css";
import Aos from 'aos';
import MyCV from '../utils/Theo-Hayeck-CV.pdf';
import "aos/dist/aos.css";
import '../components/HomePage.css';
import { Link as LinkRouter } from 'react-router';
import Typical from 'react-typical';


Modal.setAppElement('#root');
export class HomePage extends Component {

   state = {};

    AosDidMount(){
        Aos.init({duration: 1500});
        Aos.refresh();
    }

    render() {
        this.AosDidMount();
        return (
            <React.Fragment>
                <div className="App">
                <body className="App-body">
                <div className="App-navbar">
                    <LinkRouter to="/" className="navbar-button">Home</LinkRouter>
                    <LinkRouter to="/about" className="navbar-button">About Me</LinkRouter>
                    <LinkRouter to="/projects" className="navbar-button">Projects</LinkRouter>
                    <a className="navbar-button" href={MyCV} download="Theo-Hayeck-CV.pdf">Download CV</a>
                </div>
                <div>
                <img data-aos="zoom-in" src={avatar} className="App-avatar"/>
                <div data-aos="zoom-in"  data-aos-duration="1500" className="column-group">
                    <h1 className="text-name">Hi, my name is <span class="name">Theo</span></h1>
                    <h3 className='typewriter'>
                    <Typical
                        loop={Infinity}
                        wrapper="b"
                        steps={[
                            'I am a Software Engineer.',
                            2000,
                            'I review pull requests.',
                            2000,
                            'I move fast and break things.',
                            2000,
                            'I build experiences.',
                            2000,
                            'I don\'t deploy on Fridays.',
                            2000,
                            'I tinker with stuff.',
                            2000,
                            'I fix bugs.',
                            2000,
                            'I write code.',
                            2000
                        ]}
                    />
                    </h3> 
                </div>
                </div>
                    
                <h4>
                <div data-aos="zoom-in" className="socialButtons">
                    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"></link>
                    <a href="https://www.instagram.com/theohayeck" class="fa fa-instagram"></a>
                    <div cla></div>
                    <a href="https://www.linkedin.com/in/theo-hayeck-10a982168/" class="fa fa-linkedin"></a>
                    <div></div>
                    <a href="https://www.twitter.com/theohayeck" class="fa fa-twitter"></a>
                    <div></div>
                    <a href="https://www.github.com/TheoRudi" class="fa fa-github"></a>
                </div>
                </h4>
                </body>
                </div>

            </React.Fragment>
        );
    }

}

export default HomePage;
 